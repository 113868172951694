// import React, { useEffect, useRef, useState } from "react";
// import "./client.css"; // Ensure your animation CSS is imported

// const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;

// export default function Clients() {
//   const clientsRef = useRef(null); // Ref to target the blue box section
//   const [isVisible, setIsVisible] = useState(false); // State to handle visibility

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setIsVisible(true); // Element is visible
//             observer.unobserve(clientsRef.current); // Stop observing once it's visible
//           } else {
//             setIsVisible(false); // Element is not visible
//           }
//         });
//       },
//       { threshold: 0.5 } // Trigger when 50% of the element is visible
//     );

//     if (clientsRef.current) {
//       observer.observe(clientsRef.current);
//     }

//     return () => {
//       if (clientsRef.current) {
//         observer.unobserve(clientsRef.current);
//       }
//     };
//   }, []);

//   return (
//     <section className="bg-blue">
//       <div className="clients">
//         <h1 className="text-dark text-center fw-bold">
//           The only drama is our Success!
//         </h1>
//         <div className="container clients-box">
//           <div className="scroll-container">
//             <div className="scroll-track">{/* Your client logo map */}</div>
//           </div>
//         </div>
//       </div>
//       <div
//         ref={clientsRef} // Attach ref here
//         className={`container home-booknow-box ${
//           isVisible ? "fade-in" : "fade-out"
//         }`}
//       >
//         <div className="home-bookbox-2">
//           <h3 className={`text-white mb-0 ${isVisible ? "text-reveal" : ""}`}>
//             Let’s do business together, benefiting both.
//           </h3>
//           <h2
//             className={`fw-bold text-white ${
//               isVisible ? "text-reveal-delay" : ""
//             }`}
//           >
//             Chalo vyapar saathe kariye, aapne banne ne samruddhi, kariye!
//           </h2>
//           <div className="booknow-btn-custom">
//             <a href={BOOK_NOW_LINK} target="_blank" rel="noopener noreferrer">
//               Book A Demo
//             </a>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

import C1 from "../../assets/svg/Home/Clients/clients-1.svg";
import C2 from "../../assets/svg/Home/Clients/clients-2.svg";
import C3 from "../../assets/svg/Home/Clients/clients-3.svg";
import C4 from "../../assets/svg/Home/Clients/clients-4.svg";
import C5 from "../../assets/svg/Home/Clients/clients-5.svg";
import C6 from "../../assets/svg/Home/Clients/clients-6.svg";
import C7 from "../../assets/svg/Home/Clients/clients-7.svg";
import C8 from "../../assets/svg/Home/Clients/clients-8.svg";
import C9 from "../../assets/svg/Home/Clients/clients-9.svg";
import C10 from "../../assets/svg/Home/Clients/clients-10.svg";
import C11 from "../../assets/svg/Home/Clients/clients-11.svg";
import C12 from "../../assets/svg/Home/Clients/clients-12.svg";
import C13 from "../../assets/svg/Home/Clients/clients-13.svg";
const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;
export default function Clients() {
  const clients = [C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13];
  return (
    <section className="bg-blue">
      <div className="clients">
        <h1 className="text-dark text-center fw-bold">
          The only drama is our Success!
        </h1>
        <div className="container clients-box">
          <div className="scroll-container">
            <div className="scroll-track">
              {[...clients, ...clients].map((client, i) => (
                <div key={`client-${i}`} className="client-box">
                  <img src={client} alt={`Client ${i + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container home-booknow-box">
        <div className="home-bookbox-2 text-center">
          <h3 className="text-white mb-0">
            Let’s do business together, benefiting both.
          </h3>
          <h2 className="fw-bold text-white">
            Chalo vyapar saathe kariye, aapne banne ne samruddhi, kariye!
          </h2>
          <div className="booknow-btn-custom">
            <a href={BOOK_NOW_LINK} target="_blank">
              Book A Demo
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
