import React from "react";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import { StrategiesComponent } from "../../../components/Services/strategies-component/Strategies";
import "./cloud-infra.css";
import { ServiceFeatureComponent } from "../../../components/Services/service-feature/ServiceFeatureComponent";

const featureComponentData = [
  {
    headerText: "Cost-Effective Cloud Deployment",
    detailText:
      "Our pay-as-you-go pricing lets you invest only in what you need, eliminating upfront costs and maintenance fees.",
  },
  {
    headerText: "High Availability and Reliability",
    detailText:
      "Our advanced cloud infrastructure ensures reliable performance and optimal uptime for your SAP B1 system.",
  },
  {
    headerText: "Scalability and Flexibility",
    detailText:
      "Scale your SAP B1 resources effortlessly with our cloud solutions to meet growth and seasonal changes.",
  },
];
const strategiesComponentData = [
  {
    headerText: "Multi Cloud Solutions",
    detailText:
      "Cloud infrastructure enables the integration of services from various cloud providers, enhancing cost-effectiveness and functionality. ",
  },
  {
    headerText: "Public Cloud Regions",
    detailText:
      "The public cloud is supported by a network of globally distributed cloud regions, providing secure and high-performance local environments. ",
  },
  {
    headerText: "Hybrid Solutions",
    detailText:
      "These innovative solutions give clients the flexibility to choose infrastructure that perfectly meets their specific workload and compliance needs.",
  },
];
const CloudInfrastructure = () => {
  return (
    <>
      <div className="cloud-infra-main">
        <CloudHeroSection
          capText={"Scalable, secure, flexible solutions."}
          gradientColor={"linear-gradient(#eff8ff, #f1f5f900)0"}
          titleText="Transform Your Business with Cutting-Edge Cloud Infrastructure."
          detailsText="In an era where digital transformation is vital for success, our cloud infrastructure services are your gateway to innovation. By partnering with Quantum IT Solution, you can focus on your core business objectives while we handle the complexities of your cloud environment."
        />
        <ServiceFeatureComponent featureComponentData={featureComponentData} />
        <StrategiesComponent
          strategiesComponentData={strategiesComponentData}
          titleText={"Cloud Computing Strategies and Architectures"}
        />
        <section className="section-connect container">
          <div className="connect-info">
            <div className="connect-info-text-section text-center">
              <div className="connect-info-text1">
                Let’s do business together, benefiting both.
              </div>
              <div className="connect-info-text2">
                Chalo vyapar saathe kariye, aapne banne ne samruddhi, kariye!
              </div>
            </div>
            <div className="connect-info-btn">
              <button>Heya, Connect?</button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CloudInfrastructure;
