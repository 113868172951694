import P1 from '../../assets/svg/Home/Partner/P1.svg'
import P2 from '../../assets/svg/Home/Partner/P2.svg'
import P3 from '../../assets/svg/Home/Partner/P3.svg'
import P4 from '../../assets/svg/Home/Partner/P4.svg'
import P5 from '../../assets/svg/Home/Partner/P5.svg'
import P6 from '../../assets/svg/Home/Partner/P6.svg'

// import { P1, P2, P3 } from '../../assets/svg/Home/Partner'

export default function Partner() {
    return (
        <section className='partner'>
            <div className='container'>
                <h1 className='text-dark text-center fw-bold'>In partner with</h1>
                <div className="service-box">
                    {[P1, P2, P3, P4, P5, P6].map((item, i) => (
                        <div key={`S${i}`} className='box'>
                            <div className="eachslide">
                                <img
                                    src={item}
                                    loading="lazy"
                                    alt={item}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
