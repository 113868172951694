import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import HeroComponent from "../../../components/Hero/HeroComponent";
import GatoImg from "../../../assets/svg/ProductSvg/Gato.png";
import feature1Svg from "../../../assets/svg/GatoFeatureSvg/F1.svg";
import feature2Svg from "../../../assets/svg/GatoFeatureSvg/F2.svg";
import feature3Svg from "../../../assets/svg/GatoFeatureSvg/F3.svg";
import feature4Svg from "../../../assets/svg/GatoFeatureSvg/F4.svg";
import ProductFeature from "../../../components/ProductFeature/ProductFeature";
import BooknowComponent from "../../../components/BookNowComponent/BooknowComponent";
import B1 from "../../../assets/svg/GatoFeatureSvg/B1.svg";
import B2 from "../../../assets/svg/GatoFeatureSvg/B2.svg";
import B3 from "../../../assets/svg/GatoFeatureSvg/B3.svg";
import FooterPage from "../../Footer/Footer";

const productFeatureData = [
  {
    image: feature1Svg,
    cardHeader: "SAP B1 Integration",
    cardDesc:
      "Automatically submits approved entries to SAP B1 and updates records in real-time, eliminating manual data entry and ensuring accuracy.",
  },

  {
    image: feature2Svg,
    cardHeader: "Step-by-Step Verification",
    cardDesc:
      "Each stage—issuing, receiving, and closing—requires approval to ensure accuracy and quality. This verification process keeps operations smooth and error-free.",
  },
  {
    image: feature3Svg,
    cardHeader: "Report Analysis",
    cardDesc:
      "Easily track and manage all production orders with real-time status updates and flexible export options for detailed reporting.",
  },
  {
    image: feature4Svg,
    cardHeader: "User Management",
    cardDesc:
      "Create multiple users with customizable roles and authorizations. Manage permissions and access levels to ensure secure and efficient platform use.",
  },
];
const bookNowData = [
  {
    image: B1,
    text1: "5x",
    text2: "Cost Saving",
  },
  {
    image: B2,
    text1: "100%",
    text2: "Free Trial",
  },
  {
    image: B3,
    text1: "10K+",
    text2: "Clients",
  },
];
export const Gato = () => {
  return (
    <>
      <div id="Platy">
        <HeroComponent
          primaryGradiant={"#F5FAFFCC"}
          secondaryGradiant={"#F0F9FE00"}
          heroHeader={"Track Inventory in a Snap with QR Codes"}
          heroDetail={
            "Our QR Code Inventory Management System streamlines your inventory processes by enabling fast, accurate tracking and management. With just a scan, you can easily update stock levels, access detailed product information, and track inventory movement in real time."
          }
          heroImage={GatoImg}
        />
        <ProductFeature
          productFeatureData={productFeatureData}
          productionFeatureSamll={[]}
        />
        <BooknowComponent
          headingText={"Grows with your business"}
          primaryGradiant={"#0057A1CC"}
          secondaryGradiant={"#0057A1"}
          bookNowData={bookNowData}
          backgroundColor={"#DFEFFF"}
          backGroundGradient={
            "linear-gradient(90deg, rgba(0, 87, 161, 0.8) 0%, rgba(0, 87, 161, 1) 100%)"
          }
          productname={"Gato"}
        />
      </div>
    </>
  );
};
