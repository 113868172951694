import React from "react";
import Hero from "../../components/Home/Hero";
import "./Home.css";
import Solution from "../../components/Home/Solution";
import Product from "../../components/Home/Product";
import Clients from "../../components/Home/Clients";
import Partner from "../../components/Home/Partner";
import { IndustryCarousel } from "../../components/IndustriesCarousel/IndustriesCarousel";

export default function Home() {
  return (
    <div id="home">
      <Hero />
      <Solution />
      <Product />
      {/* <Industry /> */}
      <IndustryCarousel />
      <Clients />
      <Partner />
    </div>
  );
}
