import { Link } from "react-router-dom";
import qit_logo from "../../assets/svg/Qit logo.svg";
import { useLocation } from "react-router-dom";
import "./Navbar.css";
import { useEffect, useState } from "react";

export default function Navbar() {
    const location = useLocation();
    const path = location.pathname.split("/");

    const [dropdownOpen, setDropdownOpen] = useState({
        solution: false,
        product: false,
        services: false,
        company: false,
        resources: false,
    });

    // Toggle specific dropdown
    const toggleDropdown = (dropdownName) => {
        setDropdownOpen((prev) => ({
            ...prev,
            [dropdownName]: !prev[dropdownName],
        }));
    };

    // Close dropdowns on outside click
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!e.target.closest(".dropdown") || e.target.closest(".dropdown-item")) {
                setDropdownOpen({
                    solution: false,
                    product: false,
                    services: false,
                    company: false,
                    resources: false,
                });
            }
        };
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-landing fixed-top is-sticky" id="navbar">
                <div className="container">
                    <Link to="/" target="_self">
                        <img
                            src={qit_logo}
                            className="card-logo card-logo-dark"
                            alt="logo dark"
                            height="60"
                            loading="lazy"
                        />
                    </Link>
                    <button
                        className="navbar-toggler py-0 fs-20 text-body border-0"
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                        aria-controls="offcanvasExample"
                    >
                        <span className="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end text-align-end" id="navbarSupportedContent">
                        <ul className="navbar-nav align-end mt-2 mt-lg-0" id="navbar-example">
                            <li className="nav-item">
                                <Link className={`nav-link ${path[1] === "" ? "active" : ""}`} to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <div className="dropdown">
                                    <div
                                        className={`nav-link dropdown-toggle ${path[1] === "solution" ? "active" : ""}`}
                                        to="#solution"
                                        id="dropdownMenuButtonSolution"
                                        data-bs-toggle="dropdown"
                                        data-bs-autoclose="true"
                                        aria-expanded="false"
                                        onClick={() => toggleDropdown("solution")}
                                    >
                                        Solution
                                    </div>
                                    <ul
                                        className={`dropdown-menu mt-3 ${dropdownOpen.solution ? "show" : ""}`}
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <Link className="dropdown-item" to="/solution/sap-b1">
                                            SAP B1
                                        </Link>
                                        <Link className="dropdown-item" to="/solution/sap-s4-hana">
                                            SAP S/4 Hana
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/solution/sap-Analytics"
                                        >
                                            SAP Analytics Cloud
                                        </Link>
                                        <Link className="dropdown-item" to="/solution/sap-b1-up">
                                            SAP B1 UP
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/solution/power-bi-dashboard"
                                        >
                                            Power B1 Dashboard
                                        </Link>
                                        <Link className="dropdown-item" to="/solution/sap-add-on">
                                            SAP ADD ONS
                                        </Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="dropdown">
                                    <div
                                        className={`nav-link dropdown-toggle ${path[1] === "product" ? "active" : ""
                                            }`}
                                        to="#products"
                                        id="dropdownMenuButtonProducts"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-bs-autoclose="true"
                                        onClick={() => toggleDropdown("product")}
                                    >
                                        Product
                                    </div>
                                    <ul
                                        className={`dropdown-menu mt-3 ${dropdownOpen.product ? "show" : ""}`}
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <Link className="dropdown-item" to="/product/platy">
                                            Vendor & Customer Management
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/product/production-process"
                                        >
                                            Web Production Process
                                        </Link>
                                        <Link className="dropdown-item" to="/product/signakshar">
                                            E-Signakshar
                                        </Link>
                                        <Link className="dropdown-item" to="/product/crm">
                                            CRM - SARSI
                                        </Link>
                                        <Link className="dropdown-item" to="/product/gato">
                                            QR Code Inventory Management
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/product/payment-integration"
                                        >
                                            Payment Integration
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="https://swift-send.click"
                                            target="_blank"
                                        >
                                            Swiftsend
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="https://digitalsignature.qitsolution.co.in/"
                                            target="_blank"
                                        >
                                            Digital signature
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="https://tracksquare.qitsolution.co.in/"
                                            target="_blank"
                                        >
                                            Task Management
                                        </Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="dropdown">
                                    <div className={`nav-link dropdown-toggle ${path[1] === "services" ? "active" : ""}`} to="#services" id="dropdownMenuButtonServices" data-bs-toggle="dropdown" aria-expanded="false" data-bs-autoclose="true" onClick={() => toggleDropdown("services")}>
                                        Services
                                    </div>
                                    <ul
                                        className={`dropdown-menu mt-3 ${dropdownOpen.services ? "show" : ""}`}
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <Link
                                            className="dropdown-item"
                                            to="/services/web-mobile-development"
                                        >
                                            Web / Mobile Development
                                        </Link>
                                        <Link className="dropdown-item" to="/services/ux-ui-design">
                                            UX/UI Design
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/services/cloud-infrastructure"
                                        >
                                            Cloud Infrastructure
                                        </Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="dropdown">
                                    <div className={`nav-link dropdown-toggle ${path[1] === "company" ? "active" : ""}`} to="#company" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-autoclose="true" onClick={() => toggleDropdown("company")}>
                                        Company
                                    </div>
                                    <ul
                                        className={`dropdown-menu mt-3 ${dropdownOpen.company ? "show" : ""}`}
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <Link className="dropdown-item" to="/company/about">
                                            About Us
                                        </Link>
                                        <Link className="dropdown-item" to="/company/career">
                                            Career
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/page-under-construction"
                                        >
                                            Gallery
                                        </Link>
                                        <Link className="dropdown-item" to="/company/contact-us">
                                            Contact Us
                                        </Link>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="dropdown">
                                    <div
                                        className="nav-link dropdown-toggle"
                                        to="#resources"
                                        id="dropdownMenuButtonResources"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-bs-autoclose="true"
                                        onClick={() => toggleDropdown("resources")}
                                    >
                                        Resources
                                    </div>
                                    <ul
                                        className={`dropdown-menu mt-3 ${dropdownOpen.resources ? "show" : ""}`}
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <Link
                                            className="dropdown-item"
                                            to="/page-under-construction"
                                        >
                                            Blogs
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/page-under-construction"
                                        >
                                            Inside Blogs
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/page-under-construction"
                                        >
                                            Case Study
                                        </Link>
                                    </ul>
                                </div>
                            </li>
                        </ul>

                        <div className="nav-letconnect-btn">
                            <a
                                href="/company/contact-us"
                                target=""
                                className="contact-btn-custom"
                            >
                                Lets Connect?
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            <div
                className="offcanvas offcanvas-end"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
            >
                <div className="offcanvas-header pb-0">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Link to="/" target="_self">
                            <img
                                src={qit_logo}
                                className="card-logo card-logo-dark"
                                alt="logo dark"
                                height="45"
                                loading="lazy"
                            />
                        </Link>
                        <button
                            type="button"
                            className="btn-close text-reset float-end"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                </div>
                <div className="offcanvas-body navbar-small-box">
                    <div className="accordion accordion-flush custom-nav-design" id="accordionFlushExample">
                        <div className="accordion-item" data-bs-dismiss="offcanvas">
                            <h2 className="" id="flush-headingZero">
                                <Link className={`nav-link ${path[1] == "" ? "active" : ""}`} to="/">
                                    <button className="accordion-button p-0">
                                        <i className="ri-home-4-line"></i>
                                        Home
                                    </button>
                                </Link>
                            </h2>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className={`accordion-button collapsed p-0 ${path[1] === "solution" ? "active" : ""
                                    }`} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <i className="bx bxs-flask"></i>
                                    Solution
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body py-0" data-bs-dismiss="offcanvas">
                                    <Link className="dropdown-item" to="/solution/sap-b1">
                                        SAP B1
                                    </Link>
                                    <Link className="dropdown-item" to="/solution/sap-s4-hana">
                                        SAP S/4 Hana
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/solution/sap-Analytics"
                                    >
                                        SAP Analytics Cloud
                                    </Link>
                                    <Link className="dropdown-item" to="/solution/sap-b1-up">
                                        SAP B1 UP
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/solution/power-bi-dashboard"
                                    >
                                        Power B1 Dashboard
                                    </Link>
                                    <Link className="dropdown-item" to="/solution/sap-add-on">
                                        SAP ADD ONS
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className={`accordion-button collapsed p-0 ${path[1] === "product" ? "active" : ""
                                    }`} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <i className="mdi mdi-hammer-wrench"></i>
                                    Product
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body py-0" data-bs-dismiss="offcanvas">
                                    <Link className="dropdown-item" to="/product/platy">
                                        Vendor & Customer Management
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/product/production-process"
                                    >
                                        Web Production Process
                                    </Link>
                                    <Link className="dropdown-item" to="/product/signakshar">
                                        E-Signakshar
                                    </Link>
                                    <Link className="dropdown-item" to="/product/crm">
                                        CRM - SARSI
                                    </Link>
                                    <Link className="dropdown-item" to="/product/gato">
                                        QR Code Inventory Management
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/product/payment-integration"
                                    >
                                        Payment Integration
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="https://swift-send.click"
                                        target="_blank"
                                    >
                                        Swiftsend
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="https://digitalsignature.qitsolution.co.in/"
                                        target="_blank"
                                    >
                                        Digital signature
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="https://tracksquare.qitsolution.co.in/"
                                        target="_blank"
                                    >
                                        Task Management
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className={`accordion-button collapsed p-0 ${path[1] === "services" ? "active" : ""
                                    }`} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <i className="ri-settings-3-line"></i>
                                    Services
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body py-0" data-bs-dismiss="offcanvas">
                                    <Link
                                        className="dropdown-item"
                                        to="/services/web-mobile-development"
                                    >
                                        Web / Mobile Development
                                    </Link>
                                    <Link className="dropdown-item" to="/services/ux-ui-design">
                                        UX/UI Design
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/services/cloud-infrastructure"
                                    >
                                        Cloud Infrastructure
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                                <button className={`accordion-button collapsed p-0 ${path[1] === "company" ? "active" : ""
                                    }`} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    <i className="ri-building-2-fill"></i>
                                    Company
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                                data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body py-0" data-bs-dismiss="offcanvas">
                                    <Link className="dropdown-item" to="/company/about">
                                        About Us
                                    </Link>
                                    <Link className="dropdown-item" to="/company/career">
                                        Career
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/page-under-construction"
                                    >
                                        Gallery
                                    </Link>
                                    <Link className="dropdown-item" to="/company/contact-us">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFive">
                                <button className={`accordion-button collapsed p-0 ${path[1] === "resources" ? "active" : ""
                                    }`} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    <i className="mdi mdi-toolbox-outline"></i>
                                    Resources
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive"
                                data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body py-0" data-bs-dismiss="offcanvas">
                                    <Link
                                        className="dropdown-item"
                                        to="/page-under-construction"
                                    >
                                        Blogs
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/page-under-construction"
                                    >
                                        Inside Blogs
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/page-under-construction"
                                    >
                                        Case Study
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
