import React from "react";
import "./our-project-com.css";
export const OurProjectComponent = ({ ourProjectComponentData, titleText }) => {
  return (
    <section className="container">
      <div className="main-tool-container text-center">
        <span className="main-tool-title">{titleText}</span>
      </div>
      <div className="project-component-outer container">
        {ourProjectComponentData.map((item, index) => {
          return (
            <div key={index} className="our-project-component">
              <div className="img-section">
                <img src={item.headerImg} alt="project_component_image" />
              </div>
              <div className="first-section">
                <span className="header-content">{item.headerText}</span>
              </div>
              <div className="second-section">
                <span className="section-detail">{item.detailText}</span>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
