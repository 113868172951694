import React from "react";
import HeroComponent from "../../../components/Hero/HeroComponent";
import HeroPng from "../../../assets/svg/ProductSvg/signaksharSvg.svg";
import AboutPng from "../../../assets/svg/SignaksharFeatureSvg/about.svg";
import feature1Svg from "../../../assets/svg/SignaksharFeatureSvg/F1.svg";
import feature2Svg from "../../../assets/svg/SignaksharFeatureSvg/F2.svg";
import feature3Svg from "../../../assets/svg/SignaksharFeatureSvg/F3.svg";
import feature4Svg from "../../../assets/svg/SignaksharFeatureSvg/F4.svg";
import feature5Svg from "../../../assets/svg/SignaksharFeatureSvg/F5.svg";
import feature6Svg from "../../../assets/svg/SignaksharFeatureSvg/F6.svg";
import feature7Svg from "../../../assets/svg/SignaksharFeatureSvg/F7.svg";
import BBIC1 from "../../../assets/svg/SignaksharFeatureSvg/BBI1.svg";
import BBIC2 from "../../../assets/svg/SignaksharFeatureSvg/BBI2.svg";
import BBIC3 from "../../../assets/svg/SignaksharFeatureSvg/BBI3.svg";
import ProductDescription from "../../../components/ProductDescription/ProductDescription";
import ProductFeature from "../../../components/ProductFeature/ProductFeature";
import BooknowComponent from "../../../components/BookNowComponent/BooknowComponent.jsx";
import Navbar from "../../../components/Navbar/Navbar.jsx";
import FooterPage from "../../Footer/Footer.jsx";
// import BooknowComponent from "../../components/BookNowComponent/BooknowComponent";
const productFeatureData = [
  {
    image: feature1Svg,
    cardHeader: "Type, Draw or Upload",
    cardDesc:
      "You can easily sign documents by typing your name, sketching your signature, or uploading an image directly within the app.",
  },

  {
    image: feature2Svg,
    cardHeader: "Easy Signing Made Simple",
    cardDesc:
      "Our software sets up the fields and helps signees fill them out, making sure everything is completed, right down to the signature.",
  },
  {
    image: feature3Svg,
    cardHeader: "Time-Saving Templates",
    cardDesc:
      "Senders can create templates that allow them to assign fields, roles, and signing positions for other users to complete the signing process.",
  },
  {
    image: feature4Svg,
    cardHeader: "Bulk Document Signing",
    cardDesc:
      "Users can upload and sign multiple PDF files at once, streamlining the signing process across various documents to boost productivity.",
  },
];
const productionFeatureSamll = [
  {
    image: feature5Svg,
    cardHeader: "Recipient Management",
    cardDesc:
      "Assign roles and signing order to recipients, detailing their name on the document .",
  },
  {
    image: feature6Svg,
    cardHeader: "Schedule Documents",
    cardDesc:
      "Senders can schedule documents to be sent to clients at a specified time. ",
  },
  {
    image: feature7Svg,
    cardHeader: "Tracking",
    cardDesc:
      "Senders can monitor all activities associated with the document signing process.",
  },
];
const bookNowData = [
  {
    image: BBIC1,
    text1: "2x",
    text2: "Speed",
  },
  {
    image: BBIC2,
    text1: "100%",
    text2: "Free Trail",
  },
  {
    image: BBIC3,
    text1: "20k+",
    text2: "Clients",
  },
];

export const SignaksharMain = () => {
  return (
    <>
      <div className="SignaksharMain" id="Platy">
        <HeroComponent
          primaryGradiant={"#FDF2F799"}
          secondaryGradiant={"#F1F5F900"}
          heroHeader={"E- Signature Solutions: Sign with Confidence"}
          heroDetail={
            "Our e-signature software simplifies adding signatures to PDFs, replacing traditional paper signatures with a user-friendly interface. Easily upload, sign, and manage documents, making it perfect for businesses seeking to improve productivity and collaboration."
          }
          heroImage={HeroPng}
          signInBtn={true}
        />
        <ProductDescription
          ProductName={"Signakshar"}
          TextColor={"#DA1C5C"}
          Discription={
            "E-signature streamlines document signing by enabling users to electronically sign PDFs and manage documents efficiently. It offers features such as document tracking, template creation, and bulk signing, ensuring seamless workflow management."
          }
          image={AboutPng}
        />
        <ProductFeature
          productFeatureData={productFeatureData}
          productionFeatureSamll={productionFeatureSamll}
        />
        <BooknowComponent
          headingText={"Signakshar grows with your business"}
          bookNowData={bookNowData}
          primaryGradiant={"#DA1C5C99"}
          secondaryGradiant={"#DA1C5CCC"}
          backgroundColor={"#DA1C5C17"}
          backGroundGradient={
            "linear-gradient(360deg, rgba(218, 28, 92, 0.6), rgba(218, 28, 92, 0.8)"
          }
          productname={"Signakshar"}
        />
      </div>
    </>
  );
};
