import React from "react";
import "./booknowcomponent.css";

const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;
export default function BooknowComponent({
  headingText,
  backgroundColor,
  bookNowData,
  backGroundGradient,
  productname,
}) {
  return (
    <section
      className="bg-green"
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      <div className="bookbox">
        <div
          className="bookbox-1"
          style={{
            padding: bookNowData.length === 0 ? "-20px !important" : "",
          }}
        >
          <div className="container">
            <h1
              className="fw-bold text-center  book-now-growtext"
              style={{
                marginBottom: bookNowData.length === 0 ? "20px" : "",
              }}
            >
              {headingText}
            </h1>
            {bookNowData.length > 0 && (
              <div className="row justify-content-center w-70 m-auto offers-conatiner">
                {bookNowData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="BBSI-box col-lg-4 col-md-4 col-sm-12 d-flex align-items-center gap-3 border border-dark border-2 conatiner-box-book"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="icon">
                        <img
                          src={item.image}
                          height={"100%"}
                          width={"100%"}
                          alt="three_icon"
                        />
                      </div>
                      <div className="content">
                        <h3
                          className="content-heading"
                          style={{ fontSize: "40px", fontWeight: "700" }}
                        >
                          {item.text1}
                        </h3>
                        <p
                          className="text-muted content-data"
                          style={{ fontSize: "18px", fontWeight: "400" }}
                        >
                          {item.text2}
                        </p>
                      </div>
                      <div
                        className="divider"
                        style={{
                          display:
                            index === bookNowData.length - 1 ? "none" : "block",
                        }}
                      ></div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container book-now-conatiner" style={{}}>
        <div
          className="bookbox-2"
          style={{
            background: `${backGroundGradient}`,
            padding: "10px",
          }}
        >
          <h1 className="fw-bold text-white text-center">
            Try {productname} Now!
          </h1>
          <a href={BOOK_NOW_LINK} target="_blank">
            <div className="booknow-btn text-uppercase w-md-auto">
              Book Demo
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}
