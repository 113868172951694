import React, { useEffect, useState } from "react";
import "./SapVeriticalCorosal.css";
const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;
export const SapVeriticalCorosal = ({
  sections,
  V1,
  V2,
  headerText,
  detailText,
  buttonText,
  backgroundColor,
}) => {
  const [selected, setSelected] = useState(1);
  const [animate, setAnimate] = useState(false);
  const [fade, setFade] = useState(false);

  const handleChangeSelected = (component) => {
    if (component === "top") {
      setSelected(1);
      setAnimate("animate-down");
    } else if (component === "bottom") {
      setSelected(2);
      setAnimate("animate-up");
    }

    setTimeout(() => {
      setAnimate("");
    }, 800);
  };

  useEffect(() => {
    setFade(true);

    const timeout = setTimeout(() => {
      setFade(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [selected]);
  return (
    <>
      <section className="section-mission" style={{ backgroundColor }}>
        <div className="component-container container">
          <div className="left">
            <div className="common-left">
              <div className="mission-title">
                <span>{headerText}</span>
              </div>
              <div className="mission-discription">
                <p>{detailText}</p>
              </div>
              {buttonText && (
                <div className="btn-section">
                  <a href={BOOK_NOW_LINK} target="_blank">
                    <button>{buttonText}</button>
                  </a>
                </div>
              )}
            </div>
            <div className="selection-conatiner-down">
              <div className="selection-conatiner-down">
                {sections.map((section, index) => (
                  <div
                    key={section.id}
                    className="swiping-section"
                    onClick={() => handleChangeSelected(section.id)}
                  >
                    <div className="border-active"></div>
                    {selected === index + 1 && (
                      <div className={`border-selected ${animate}`}></div>
                    )}
                    {selected !== index + 1 && (
                      <div className="border-deselected"></div>
                    )}
                    <div className="section-text">
                      <div
                        className={
                          selected !== index + 1
                            ? "text1-not-selected"
                            : "text1"
                        }
                      >
                        {section.title}
                      </div>
                      <div
                        className={
                          selected !== index + 1
                            ? "text2-not-selected"
                            : "text2"
                        }
                      >
                        {section.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="right">
            {selected === 1 ? (
              <img
                src={V1}
                alt="mission-section-image"
                // height={440}
                // width={401}
                className={fade ? "fade-in" : ""}
              />
            ) : (
              <img
                src={V2}
                alt="mission-section-image"
                // height={440}
                // width={401}
                className={fade ? "fade-in" : ""}
              />
            )}
          </div>
        </div>
        <div className="mobile-component-container container">
          <div className="common-left">
            <div className="mission-title">
              <span>{headerText}</span>
            </div>
            <div className="mission-discription">
              <p>{detailText}</p>
            </div>
            {buttonText && (
              <div className="btn-section">
                <button>{buttonText}</button>
              </div>
            )}
          </div>
          <div className="selection-container-down">
            {sections.map((section, index) => (
              <div className="section-text" key={index}>
                <div className="text1">{section.title}</div>
                <div className="text2">{section.description}</div>
                {index === 0 && (
                  <img
                    src={V1}
                    alt="section-image-1"
                    className={fade ? "fade-in" : ""}
                  />
                )}
                {index === 1 && (
                  <img
                    src={V2}
                    alt="section-image-2"
                    className={fade ? "fade-in" : ""}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
