import React from "react";
import "./featureComponent.css";

export const FeatureComponent = ({ featureComponentData, titleText }) => {
  return (
    <>
      <div className="main-outer-container">
        <span className="main-title">{titleText}</span>
      </div>
      <div className="feature-component-box container">
        {featureComponentData.map((item, index) => {
          return (
            <div key={index} className="feature-component">
              <div className="first-section">
                <div className="header-line"></div>
                <div className="header-content">
                  <span>{item.headerText}</span>
                </div>
              </div>
              <div className="second-section">
                <div className="section-detail">
                  <span>{item.detailText}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
