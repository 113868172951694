import React from "react";
import "./sapFeatureSection.css";

export const SapFeatureSection = ({ featureData, title }) => {
  return (
    <>
      <section className="SapFeatureSection">
        <div className="container">
          <div className="feature-text">
            <p>{title}</p>
          </div>

          {featureData.map((item, index) => {
            return (
              <div
                key={index}
                className={`feature-detail-card ${item.flexDirection === "row-reverse" ? "reverse" : ""
                  }`}
              >
                <div className="left">
                  <img src={item.image} alt="feature-pic" loading="lazy"  />
                </div>
                <div className="right">
                  <div className="feature-title">{item.cardTitle}</div>
                  <div className="feature-text1">
                    <p>{item.text1}</p>
                  </div>
                  <div className="feature-text2">
                    <p>{item.text2}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
