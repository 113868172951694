// src/components/SapHeroSection.js
import React from "react";
import PropTypes from "prop-types";

import "./sapherosection.css";
import "animate.css";
import PreloadedImage from "../../PreloadImage/PreloadedImage";

const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;

export const SapHeroSection = ({
  capText,
  titleText,
  detailsText,
  gradientColor,
  image,
}) => {
  return (
    <div
      className="outer-container" /* Fixed typo: "outer-conatiner" to "outer-container" */
      style={{ background: `${gradientColor}` }}
    >
      <section className="sap-hero container">
        <div className="left">
          <div className="left-cap">
            <span>{capText}</span>
          </div>
          <div className="hero-title">
            <span>{titleText}</span>
          </div>
          <div className="hero-detail">
            <p>{detailsText}</p>
          </div>
          <div className="hero-btn">
            <a href={BOOK_NOW_LINK} target="_blank" rel="noopener noreferrer">
              <button>Book a Demo</button>
            </a>
          </div>
        </div>
        <div className="right">
          <div className="hero-image">
            <PreloadedImage
              src={image}
              alt="hero-section-img"
              height="100%"
              className="hero-img" /* Add a specific class for styling */
            />
          </div>
        </div>
      </section>
    </div>
  );
};

SapHeroSection.propTypes = {
  capText: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  detailsText: PropTypes.string.isRequired,
  gradientColor: PropTypes.string,
  image: PropTypes.string.isRequired,
};

SapHeroSection.defaultProps = {
  gradientColor: "#ffffff", // Default gradient color
};
