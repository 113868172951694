import React from "react";
import "./animatedbutton.css";

export const AnimatedButton = () => {
  return (
    <>
      <div className="AnimatedButton" id="AnimatedButton">
        <div className="box-3">
          <div className="btn btn-three">
            <span>Sign up Now!</span>
          </div>
        </div>
      </div>
    </>
  );
};
