import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import HeroComponent from "../../../components/Hero/HeroComponent";
import HeroPng from "../../../assets/svg/ProductSvg/PaymentIntegration.png";
import { FeatureComponent } from "../../../components/FeatureComponent/FeatureComponent";
import { ProductFeatureCard } from "../../../components/ProductFeatureCard/ProductFeatureCard";
import F1 from "../../../assets/svg/PaymentIntegration/F1.svg";
import F2 from "../../../assets/svg/PaymentIntegration/F2.svg";
import F3 from "../../../assets/svg/PaymentIntegration/F3.svg";
import Solution from "../../../components/Platy/Solution";
import BooknowComponent from "../../../components/BookNowComponent/BooknowComponent";
import FooterPage from "../../Footer/Footer";

export const PaymentIntegration = () => {
  const featureComponentData = [
    {
      headerText: "Transaction Sync",
      detailText:
        "Transactions from SAP are synced with the payment integration software.",
    },
    {
      headerText: "Approval Process",
      detailText:
        "Users approve or reject transactions, sending approved data securely to HDFC.",
    },
    {
      headerText: "Response from HDFC",
      detailText:
        "HDFC confirms with a UTR number or returns an error for corrections.",
    },
  ];

  const featureCardData = [
    {
      image: F1,
      headerText: "Data Encrypted ",
      detailText:
        "Your information is securely encrypted, ensuring confidentiality.",
    },
    {
      image: F2,
      headerText: "Step By Step Verification",
      detailText:
        "You cannot proceed without a decision, and remarks are required for any rejection.",
    },
    {
      image: F3,
      headerText: "SAP B1 Integration",
      detailText:
        "Syncs entries to SAP B1 in real-time, ensuring accurate data.",
    },
  ];
  return (
    <>
      <div id="Platy">
        <HeroComponent
          primaryGradiant={"#DFEFFF99"}
          secondaryGradiant={"#F0F9FE00"}
          heroHeader={"Secure and Efficient HDFC Payment Integration"}
          heroDetail={
            "Integrate seamlessly with HDFC for smooth payment processing, ensuring secure transactions and efficient financial management. This integration streamlines your payment workflows, allowing for quick and easy processing of transactions"
          }
          heroImage={HeroPng}
        />
        <FeatureComponent
          featureComponentData={featureComponentData}
          titleText="Seamless Transaction"
        />
        <ProductFeatureCard featureCardData={featureCardData} />
        <Solution />
        <BooknowComponent
          headingText={"Ensuring Safety in Every Transaction"}
          bookNowData={[]}
          backGroundGradient={"linear-gradient(90deg, #0057A1CC,#0057A1 "}
          backgroundColor={"#DFEFFF"}
          productname={"Payment Integration"}
        />
      </div>
    </>
  );
};
