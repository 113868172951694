import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import HeroComponent from "../../../components/Hero/HeroComponent";
import crmPng from "../../../assets/svg/ProductSvg/Crm.png";
import Solution from "../../../components/Platy/Solution";
import ProductDescription from "../../../components/ProductDescription/ProductDescription";
import aboutImg from "../../../assets/svg/CrmProduct/about.png";
import ProductFeature from "../../../components/ProductFeature/ProductFeature";
import F1 from "../../../assets/svg/CrmProduct/F1.png";
import F2 from "../../../assets/svg/CrmProduct/F2.png";
import F3 from "../../../assets/svg/CrmProduct/F3.png";
import F4 from "../../../assets/svg/CrmProduct/F4.png";
import F5 from "../../../assets/svg/CrmProduct/F5.png";
import F6 from "../../../assets/svg/CrmProduct/F6.png";
import F7 from "../../../assets/svg/CrmProduct/F7.png";
import B1 from "../../../assets/svg/CrmProduct/B1.svg";
import B2 from "../../../assets/svg/CrmProduct/B2.svg";
import B3 from "../../../assets/svg/CrmProduct/B3.svg";
import BooknowComponent from "../../../components/BookNowComponent/BooknowComponent";
import FooterPage from "../../Footer/Footer";

export const Crm = () => {
  const productFeatureData = [
    {
      image: F1,
      cardHeader: "Lead Management",
      cardDesc:
        "Easily add and update lead details in the CRM, tracking all communications and actions. Stay organised and manage leads efficiently for better follow-ups.",
    },

    {
      image: F2,
      cardHeader: "Product Master",
      cardDesc:
        "Add and manage a comprehensive product master, keeping all item details organised and up-to-date for efficient tracking.",
    },
    {
      image: F3,
      cardHeader: "User Management",
      cardDesc:
        "Create multiple users with customizable roles and authorizations. Manage permissions and access levels to ensure secure and efficient platform use.",
    },
    {
      image: F4,
      cardHeader: "Quotation & Sales Order",
      cardDesc:
        "Enables you to easily create and email multiple quotations to clients and you can quickly generate and send sales orders directly from the platform.",
    },
  ];
  const productionFeatureSamll = [
    {
      image: F5,
      cardHeader: "Clarifying Decisions",
      cardDesc:
        "Add reasons for every approval or rejection to ensure transparency.",
    },
    {
      image: F6,
      cardHeader: "Live Support",
      cardDesc:
        "Provides real-time help for queries or issues, ensuring prompt resolutions.",
    },
    {
      image: F7,
      cardHeader: "Status Management",
      cardDesc:
        "Track entries as Pending, Approved, or Rejected, with required remark for rejection",
    },
  ];
  const bookNowData = [
    {
      image: B1,
      text1: "5x",
      text2: "Cost Saving",
    },
    {
      image: B2,
      text1: "100%",
      text2: "Free Trial",
    },
    {
      image: B3,
      text1: "10k+",
      text2: "Clients",
    },
  ];
  return (
    <>
      <div id="Platy">
        <HeroComponent
          primaryGradiant={"#F5FAFFCC"}
          secondaryGradiant={"#F0F9FE00"}
          heroHeader={`Empowering Connections with Smart CRM Solutions`}
          heroDetail={
            "Our SAP-integrated CRM solution transforms how you connect with customers. Streamline interactions, manage relationships, and enhance customer satisfaction—all in one platform. Experience the power of seamless integration with SAP. "
          }
          heroImage={crmPng}
        />
        <Solution />
        <ProductDescription
          ProductName={"CRM"}
          TextColor={"#2575D0"}
          Discription={
            "Customer Relationship Management is a system businesses use to manage customer interactions, streamline processes, and improve service. It helps organize data, track communications, and automate workflows for sales, marketing, and support."
          }
          image={aboutImg}
        />
        <ProductFeature
          productFeatureData={productFeatureData}
          productionFeatureSamll={productionFeatureSamll}
        />
        <BooknowComponent
          headingText={"Grows with your business"}
          bookNowData={bookNowData}
          backGroundGradient={"linear-gradient(90deg, #43A7EE,#338DDE ,#2575D0"}
          backgroundColor={"#DCF1FDB2"}
          productname={"SARSI"}
        />
      </div>
    </>
  );
};
