import "./App.css";
import { Route, Routes } from "react-router-dom";
import "./assets/css/app.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.min.css";
import "./assets/css/icons.min.css";
import {
  About,
  CareerPage,
  CloudInfrastructure,
  ContactPage,
  Crm,
  Gato,
  Home,
  PageNotFound,
  PageUnderConstruction,
  PaymentIntegration,
  Platy,
  PowerBiDashBoard,
  ProductionProcess,
  SapAddOns,
  SapAnalytics,
  SapB1Page,
  SapB1Up,
  SapS4HanaPage,
  SignaksharMain,
  UXUIDesign,
  WebMobileDevelopment,
} from "./Pages/index.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Layout from "./Layout.jsx";
import ScrollToTop from "./ScrollTop.jsx";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <div className="layout-wrapper landing">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/product/platy" element={<Platy />} />
            <Route
              path="/product/production-process"
              element={<ProductionProcess />}
            />
            <Route path="/product/signakshar" element={<SignaksharMain />} />
            <Route path="/product/crm" element={<Crm />} />
            <Route path="/product/gato" element={<Gato />} />
            <Route
              path="/product/payment-integration"
              element={<PaymentIntegration />}
            />
            <Route
              path="/services/cloud-infrastructure"
              element={<CloudInfrastructure />}
            />
            <Route
              path="/services/web-mobile-development"
              element={<WebMobileDevelopment />}
            />
            <Route path="/services/ux-ui-design" element={<UXUIDesign />} />
            <Route path="/solution/sap-b1" element={<SapB1Page />} />
            <Route path="/solution/sap-Analytics" element={<SapAnalytics />} />
            <Route path="/solution/sap-s4-hana" element={<SapS4HanaPage />} />
            <Route
              path="/solution/power-bi-dashboard"
              element={<PowerBiDashBoard />}
            />
            <Route path="/solution/sap-b1-up" element={<SapB1Up />} />
            <Route path="/solution/sap-add-on" element={<SapAddOns />} />
            <Route path="/company/about" element={<About />} />
            <Route path="/company/career" element={<CareerPage />} />
            <Route path="/company/contact-us" element={<ContactPage />} />
            <Route
              path="/page-under-construction"
              element={<PageUnderConstruction />}
            />
            <Route path="/sap-partner-surat" element={<Home />} />
            <Route path="/sap-partner-mumbai" element={<Home />} />
            <Route path="/sap-partner-bangalore" element={<Home />} />
            <Route path="/sap-partner-ahmedabad" element={<Home />} />
            <Route path="/sap-partner-vadodara" element={<Home />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
