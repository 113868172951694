import React from "react";
import "./tools.css";
import { Figma, Illustrator, Photoshop, XD } from "../../../assets";

export const ToolsWeUse = ({ toolsComponentData, titleText }) => {
  const clients = [Figma, XD, Illustrator, Photoshop];
  return (
    <div className="container">
      <div className="main-tool-container text-center">
        <span className="main-tool-title">{titleText}</span>
      </div>
      <div className="tools-component-box">
        {toolsComponentData.map((item, index) => {
          return (
            <div key={index} className="tools-component">
              <div className="img-section">
                <img src={item.headerImg} alt="tools_image " />
              </div>
            </div>
          );
        })}
      </div>
      <div className="tools">
        <div className="container tools-box">
          <div className="scroll-container">
            <div className="scroll-track">
              {[...clients, ...clients].map((client, i) => (
                <div key={`client-${i}`} className="client-box">
                  <img src={client} alt={`Client ${i + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
