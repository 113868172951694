import React from "react";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import { OurProjectComponent } from "../../../components/Services/our-projects/OurProjectComponent";
import {
  CRM,
  Figma,
  Illustrator,
  Itterative,
  Photoshop,
  Signakshar,
  Vendor,
  Wireframes,
  XD,
} from "../../../assets";
import { ToolsWeUse } from "../../../components/Services/tools-we-use/ToolsWeUse";
import { SapVeriticalCorosal } from "../../../components/sap-pages-components/sap-vertical-corosal/SapVerticalCorosal";
import { ServiceFeatureComponent } from "../../../components/Services/service-feature/ServiceFeatureComponent";
const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;

const featureComponentData = [
  {
    headerText: "Brand Identity",
    detailText:
      "A well-designed product can help to create a strong brand identity and encourage customers to keep using the product",
  },
  {
    headerText: "User Satisfaction",
    detailText:
      "Product is easy to use and navigate, which leads to a better user experience and will recommend it to others.",
  },
  {
    headerText: "Boost Sales & Revenue",
    detailText:
      "The user-friendly product can make it easier for customers to find and purchase the products and services they need.",
  },
];

const ourProjectComponentData = [
  {
    headerImg: Signakshar,
    headerText: "Report - LA",
    detailText:
      "Report La is a project developed for the client to view all reports in one place, utilizing C# and React.",
  },
  {
    headerImg: CRM,
    headerText: "Energy and Utilities",
    detailText:
      "Generates and prints QR codes for A/P Invoices and Receipts from SAP based on user-selected filters.",
  },
  {
    headerImg: Vendor,
    headerText: "E-Invoice Syncing App",
    detailText:
      "This app fetches AR data from SAP, converts it to JSON, updates the URN, and syncs in 1.5 to 2.5 seconds.",
  },
];
const toolsComponentData = [
  {
    headerImg: Figma,
  },
  {
    headerImg: XD,
  },
  {
    headerImg: Photoshop,
  },
  {
    headerImg: Illustrator,
  },
];
const sections = [
  {
    id: "top",
    title: "Wireframes",
    description:
      "We create IT solutions that boost efficiency and business growth.",
  },
  {
    id: "bottom",
    title: "Iterative Process",
    description: "We harness technology to drive your success and growth.",
  },
];
const UXUIDesign = () => {
  return (
    <>
      <div className="cloud-infra-main">
        <CloudHeroSection
          capText={"Aka UX/UI Magician!"}
          gradientColor={"linear-gradient(#eff8ff, #f1f5f900)0"}
          titleText="Innovative UX/UI Design for Meaningful and Impactful Interactions"
          detailsText="We create high-quality, user-friendly mobile applications tailored to meet the needs of the end user. Our experts specialize in developing both native and hybrid apps to   client requirements. Every app we build is designed to stand out and succeed in the market. With a team of skilled developers and designers, we ensure an exceptional user experience by leveraging the latest technologies and best practices to deliver secure, scalable solutions."
        />
        <SapVeriticalCorosal
          sections={sections}
          V1={Wireframes}
          V2={Itterative}
          headerText={"Our Process"}
          detailText={
            "Driven by our passion for design, we create interconnected and seamless user experiences."
          }
        />
        <ServiceFeatureComponent featureComponentData={featureComponentData} />
        <OurProjectComponent
          ourProjectComponentData={ourProjectComponentData}
          titleText={"Our Projects"}
        />
        <ToolsWeUse
          toolsComponentData={toolsComponentData}
          titleText={"Tools we use"}
        />

        <section className="section-connect container">
          <div className="connect-info">
            <div className="connect-info-text-section text-center">
              <div className="connect-info-text1">
                Let’s do business together, benefiting both.
              </div>
              <div className="connect-info-text2">
                Chalo vyapar saathe kariye, aapne banne ne samruddhi, kariye!
              </div>
            </div>
            <div className="connect-info-btn">
              <a href={BOOK_NOW_LINK} target="_blank">
                <button>Heya, Connect?</button>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UXUIDesign;
