import React, { useEffect, useState } from "react";
import I1 from "../../assets/svg/Home/Industry/I1.svg";
import I2 from "../../assets/svg/Home/Industry/I2.svg";
import I3 from "../../assets/svg/Home/Industry/I3.svg";
import I4 from "../../assets/svg/Home/Industry/I4.svg";
import I5 from "../../assets/svg/Home/Industry/I5.svg";
import I6 from "../../assets/svg/Home/Industry/I6.svg";
import I7 from "../../assets/svg/Home/Industry/I7.svg";
import I8 from "../../assets/svg/Home/Industry/I8.svg";
import I9 from "../../assets/svg/Home/Industry/I9.svg";
import "./IndustriesCarousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

export const IndustryCarousel = () => {
  const data = [
    {
      name: "Manufacturing",
      image: I1,
      content:
        "Streamlines production planning, inventory management, quality assurance, and quality control processes.",
    },
    {
      name: "Health care",
      image: I2,
      content: "Ensures batch tracking, compliance, and quality assurance.",
    },
    {
      name: "Construction",
      image: I3,
      content:
        "Enhance project management, resource allocation, and financial oversight for timely execution.",
    },
    {
      name: "Retail",
      image: I4,
      content:
        "It supports point-of-sale integration, inventory management, and CRM, ensuring smooth retail operations.",
    },
    {
      name: "Energy and Utilities",
      image: I5,
      content: "Ensures batch tracking, compliance, and quality assurance.",
    },
    {
      name: "Automotive",
      image: I6,
      content:
        "Boost customer service and streamline parts distribution with optimized inventory management.",
    },
    {
      name: "Chemical & Dyes",
      image: I7,
      content:
        "Streamline production, manage regulatory compliance, and optimize inventory transfer control.",
    },
    {
      name: "Food & Beverages",
      image: I8,
      content:
        "Control inventory, track production, and ensure safety compliance.",
    },
    {
      name: "Textile",
      image: I9,
      content:
        "Enhance supply chain efficiency, manage inventory, and improve production processes.",
    },
  ];
  const [dividedArray, setDividedArray] = useState([]);

  const divideArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      // Small screens: divide into 1 part
      setDividedArray(divideArray(data, 1));
    } else if (screenWidth >= 600 && screenWidth < 900) {
      // Medium screens: divide into 2 parts
      setDividedArray(divideArray(data, 1));
    } else if (screenWidth >= 901 && screenWidth < 1200) {
      // Medium screens: divide into 2 parts
      setDividedArray(divideArray(data, 2));
    } else {
      // Large screens: divide into 3 parts
      setDividedArray(divideArray(data, 3));
    }
  };

  useEffect(() => {
    handleResize(); // Initial call on component mount
    window.addEventListener('resize', handleResize); // Listen to window resize events

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <section className="industry">
        <div className="container">
          <h1 className="service-heading semibold-text text-center">
            Every Industry’s, Best Friend!
          </h1>
          <div className="m-auto">
            {dividedArray && (
              <Swiper
                modules={[Autoplay, Pagination]}
                // spaceBetween={24}
                // slidesPerView={1}
                autoplay={{
                  delay: 5500,
                  disableOnInteraction: true,
                }}
                loop={true}
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                speed={1000}
              >
                {dividedArray.map((items, i) => (
                  <SwiperSlide key={`swiper${i}`}>
                    <div className="d-flex justify-content-center gap-3">
                      {items.map((item, i) => (
                        <div key={`S${i}`} className="industryeachslide">
                          <img
                            className="industryeachslide-img"
                            src={item.image}
                            alt={item.name}
                          />
                          <h5 className="semibold-text text-start mt-3">
                            {item.name}
                          </h5>
                          <p className="text-start text-muted mt-3">
                            {item.content}
                          </p>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
