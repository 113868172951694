import React from "react";
import { SapHeroSection } from "../../../components/sap-pages-components/sap-hero-section/SapHeroSection";
import heroImg from "../../../assets/svg/sapB1/hero-section/hero.svg";
import "./Sapb1Page.css";
import { SapFeatureSection } from "../../../components/sap-pages-components/sap-feature-section/SapFeatureSection";
import F1 from "../../../assets/svg/sapB1/sapB1feature/F1.svg";
import F2 from "../../../assets/svg/sapB1/sapB1feature/F2.svg";
import F3 from "../../../assets/svg/sapB1/sapB1feature/F3.svg";
import F4 from "../../../assets/svg/sapB1/sapB1feature/F4.svg";
import { SapVeriticalCorosal } from "../../../components/sap-pages-components/sap-vertical-corosal/SapVerticalCorosal";
import { IndustryCarousel } from "../../../components/IndustriesCarousel/IndustriesCarousel";
import V1 from "../../../assets/svg/sapB1/sapB1feature/V1.svg";
import V2 from "../../../assets/svg/sapB1/sapB1feature/V2.svg";

export const SapB1Page = () => {
  const featureData = [
    {
      image: F1,
      cardTitle: "Financial Management",
      text1:
        "SAP Business One simplifies finance management with tools for accounts, budgets, and costs, tracks fixed assets, automates bank transactions, and provides insightful reports.",
      text2:
        "Accounting, Controlling, Fixed Asset Management, Banking and Reconciliation, Financial Reporting and Analysis",
      flexDirection: "row",
    },
    {
      image: F2,
      cardTitle: "Purchasing & Inventory Control",
      text1:
        "Improves purchasing and inventory control with efficient procurement, master data management, and streamlined accounts payable, offering reporting tools for performance insights.",
      text2:
        "Procurement, Master Data Management, Warehouse & Accounting Integration, Account Payable, Reporting.",
      flexDirection: "row-reverse",
    },
    {
      image: F3,
      cardTitle: "Sales & Customer Management",
      text1:
        "Streamlines sales by tracking leads, managing marketing campaigns, and maintaining customer data, all with mobile access for convenience.",
      text2:
        "Sales and Opportunity Management, Marketing Campaign, Customer Management, Service Management, Reporting Analysis",
      flexDirection: "row",
    },
    {
      image: F4,
      cardTitle: "Business Intelligence",
      text1:
        "Provides customisable reports, interactive analysis, intuitive tools, dynamic dashboards, and real-time analytics to enhance decision-making.",
      text2:
        "Report Creation and Customisation, Interactive Analysis, Dashboards, Real Time Analytics",
      flexDirection: "row-reverse",
    },
  ];
  const sections = [
    {
      id: "top",
      title: "From Challenges to Success",
      description:
        "Overcoming challenges, we implemented SAP B1 to transform operations",
    },
    {
      id: "bottom",
      title: "Driving Growth with SAP B1",
      description:
        "Unlocking new growth opportunities through the power of SAP Business One",
    },
  ];
  return (
    <>
      <div className="sapb1page">
        <SapHeroSection
          capText={"SAP Business One - Your business magician!"}
          gradientColor={"linear-gradient(#eff8ff, #f1f5f900)0"}
          titleText="We bring SAP B1 to Your Business – Smarter, Faster, Better."
          detailsText="Boost your business with SAP Business One Services! We help you streamline your operations and work smarter, making it easier for you to grow and succeed."
          image={heroImg}
        />
        <section className="hero-section-2">
          <p>
            An affordable <b> ERP solution</b> designed to help businesses
            manage various operations, including{" "}
            <b>accounting, financials, purchasing, inventory, sales,</b>{" "}
            customer relationships, and reporting analytics.
          </p>
        </section>
        <IndustryCarousel />
        <SapFeatureSection
          featureData={featureData}
          title={"Features that make work fun—business grows faster!"}
        />
        <SapVeriticalCorosal
          backgroundColor={"#edf8ff99"}
          buttonText={"Book a demo"}
          sections={sections}
          V1={V1}
          V2={V2}
          headerText={"SAP B1 Implementation"}
          detailText={
            "Achieving a seamless and successful SAP implementation for optimized business performance."
          }
        />
      </div>
    </>
  );
};
