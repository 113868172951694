// src/components/PreloadedImage.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PreloadImage.css"; // Import CSS for the blur effect

const PreloadedImage = ({ src, alt, className, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoaded(true);

    return () => {
      img.onload = null;
    };
  }, [src]);

  return (
    <img
      src={src}
      alt={alt}
      className={`${className} ${isLoaded ? "loaded" : "loading"}`}
      {...props}
    />
  );
};

PreloadedImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PreloadedImage.defaultProps = {
  className: "",
};

export default React.memo(PreloadedImage);
