import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import "../CloudInfrastructure/cloud-infra.css";
import "./web-mob-dev.css";
import { OurProjectComponent } from "../../../components/Services/our-projects/OurProjectComponent";
import reportLA from "../../../assets/svg/Services/ReportLA.png";
import Energy from "../../../assets/svg/Services/Energy.png";
import EInvoice from "../../../assets/svg/Services/Einvoice.png";
import { F1, F2, F3, F4, F5, F6, F7, F8, F9 } from "../../../assets/index";
import FooterPage from "../../Footer/Footer";
import { ServiceFeatureComponent } from "../../../components/Services/service-feature/ServiceFeatureComponent";
const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;
const featureComponentData = [
  {
    headerText: "Hybrid Development",
    detailText:
      "Cross-platform apps that function in various environments, blending native and web technologies uniquely.",
  },
  {
    headerText: "Native Development",
    detailText:
      "Our developers can build high-quality native apps for Android and IOS, aligning with business and security needs.",
  },
  {
    headerText: "Progressive Web App",
    detailText:
      "The future of web and mobile experiences. It combines speed, offline access, and cross-device compatibility.",
  },
];

const ourProjectComponentData = [
  {
    headerImg: reportLA,
    headerText: "Report - LA",
    detailText:
      "Report La is a project developed for the client to view all reports in one place, utilizing C# and React.",
  },
  {
    headerImg: Energy,
    headerText: "Energy and Utilities",
    detailText:
      "Generates and prints QR codes for A/P Invoices and Receipts from SAP based on user-selected filters.",
  },
  {
    headerImg: EInvoice,
    headerText: "E-Invoice Syncing App",
    detailText:
      "This app fetches AR data from SAP, converts it to JSON, updates the URN, and syncs in 1.5 to 2.5 seconds.",
  },
];

const WebMobileDevelopment = () => {
  const clients = [F1, F2, F3, F4, F5, F6, F7, F8, F9];
  return (
    <>
      <div className="cloud-infra-main">
        <CloudHeroSection
          capText={"Effortless Mobile & Web Development"}
          gradientColor={"linear-gradient(#eff8ff, #f1f5f900)0"}
          titleText="Your vision, our expertise—turning ideas into reality with mobile and web development."
          detailsText="We create high-quality, user-friendly mobile applications tailored to meet the needs of the end user. Our experts specialize in developing both native and hybrid apps to   client requirements. Every app we build is designed to stand out and succeed in the market. With a team of skilled developers and designers, we ensure an exceptional user experience by leveraging the latest technologies and best practices to deliver secure, scalable solutions."
        />
        <ServiceFeatureComponent featureComponentData={featureComponentData} />
        <OurProjectComponent
          ourProjectComponentData={ourProjectComponentData}
          titleText={"Our Projects"}
        />
        <div className="clients">
          <h1 className="framework-text text-center">
            Frameworks we work with
          </h1>
          <div className="container clients-box">
            <div className="scroll-container">
              <div className="scroll-track">
                {[...clients, ...clients].map((client, i) => (
                  <div key={`client-${i}`} className="client-box">
                    <img src={client} alt={`Client ${i + 1}`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <section className="section-connect container">
          <div className="connect-info">
            <div className="connect-info-text-section text-center">
              <div className="connect-info-text1">
                Let’s do business together, benefiting both.
              </div>
              <div className="connect-info-text2">
                Chalo vyapar saathe kariye, aapne banne ne samruddhi, kariye!
              </div>
            </div>
            <div className="connect-info-btn">
              <a href={BOOK_NOW_LINK} target="_blank">
                <button>Heya, Connect?</button>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WebMobileDevelopment;
