import "./ProductFeature.css";
import smile_icon_121 from "../../assets/svg/image 121.svg";
const ProductFeature = ({
  image,
  productFeatureData,
  productionFeatureSamll,
}) => {
  const Card = ({ SVG, header, text }) => {
    return (
      <div className="feature-box">
        <div className="feature-box-img">
          <img
            src={SVG}
            height={"100%"}
            width={"100%"}
            loading="lazy"
            style={{ objectFit: "cover", borderRadius: "24px 24px 0px 0px" }}
            alt="svgs"
          />
        </div>
        <div className="feature-box-content">
          <h4
            className="fw-semibold"
            style={{ color: "#101828", textAlign: "left" }}
          >
            {" "}
            {header}
          </h4>
          <p style={{ color: "#475467", textAlign: "left" }}>{text}</p>
        </div>
      </div>
    );
  };
  const SmallCard = ({ SVG, header, text }) => {
    return (
      <div className="feature-box">
        <div className="feature-box-img" style={{ height: "234px" }}>
          <img src={SVG} height={"100%"} width={"100%"} loading="lazy" alt="svgs" />
        </div>
        <div className="feature-box-content">
          <h4 style={{ fontWeight: "400", textAlign: "left" }}>{header}</h4>
          <p
            className="text-muted"
            style={{ fontWeight: "400", fontSize: "16px", textAlign: "left" }}
          >
            {text}
          </p>
        </div>
      </div>
    );
  };
  return (
    <section className="product-feature-section">
      <div className="container">
        <div className="feature-heading fw-bold text-start">
          Features So Good, Even Your Clients Will Smile{" "}
          <img src={smile_icon_121} height={48} content="cover" loading="lazy" alt="svgs" />
        </div>
        <div className="feature-sub-heading gray-text-muted text-start">
          Experience seamless satisfaction and effortless interaction with
          intuitive tools and responsive support.
        </div>
        <div className="row justify-content-center">
          {productFeatureData.map((item, index) => {
            return (
              <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                <Card
                  SVG={item.image}
                  header={item.cardHeader}
                  text={item.cardDesc}
                />
              </div>
            );
          })}

          {productionFeatureSamll.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                <SmallCard
                  SVG={item.image}
                  header={item.cardHeader}
                  text={item.cardDesc}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProductFeature;
