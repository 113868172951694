import "./hero-component.css";
import smile_icon_31 from "../../assets/svg/image 31.svg";
import { AnimatedButton } from "../AnimatedButton/AnimatedButton";

const HeroComponent = ({
  primaryGradiant,
  secondaryGradiant,
  heroHeader,
  heroDetail,
  heroImage,
  signInBtn,
}) => {
  return (
    <section
      className="hero-gradient hero-section-responsive"
      style={{
        background: `linear-gradient(${primaryGradiant}, ${secondaryGradiant})`,
      }}
    >
      <div className="container border border-2">
        <div className="row justify-content-center">
          <div
            className="hero-label w-auto px-5"
            style={{ border: "1px solid #d0d5dd" }}
          >
            <p
              className="text-center fw-midbold"
              style={{ color: "#182230", fontSize: "14px" }}
            >
              Book Your Free 15-Day Trial Demo Now!
              <img
                src={smile_icon_31}
                alt="Smile Icon"
                height={24}
                style={{ verticalAlign: "middle", marginLeft: "5px" }}
              />
              <span> Start Thriving!</span>
            </p>
          </div>
          <div className="col-lg-10 col-sm-10">
            <div className="text-center">
              <div className="hero-font fw-semibold">
                <span
                  className="m-0 p-0  "
                  style={{
                    fontSize: "68px",
                    fontWeight: "800",
                    color: "#182230",
                  }}
                >
                  {heroHeader}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-sm-10">
            <p
              className="text-center gray-text-muted  lh-base"
              style={{ fontSize: "20px", fontWeight: "400", marginBottom: "28px" }}
            >
              {heroDetail}
            </p>
          </div>
          <div
            className=""
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {signInBtn && <AnimatedButton />}
          </div>
          <div className="hero-svg d-flex justify-content-center">
            <img
              src={heroImage}
              alt="Hero Illustration"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroComponent;
