import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./page-under.css";
import Group from "../../../assets/svg/ExternalPages/Group.png";

const PageUnderConstruction = () => {
  return (
    <>
      <div className="page-under-main">
        <div className="hero-sub-details container text-center">
          <img src={Group} alt="page_under_construction" />
          <p className="heading">We're cooking up something great!</p>
          <p className="sub-heading">
            Just a little patience while we whip this page into shape, feel free
            <br />
            to explore our other pages.
          </p>
        </div>
      </div>
    </>
  );
};

export default PageUnderConstruction;
