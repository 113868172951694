import React from "react";
import "./cloud-herosection.css";
import "animate.css";
const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;

export const CloudHeroSection = ({
  capText,
  titleText,
  detailsText,
  gradientColor,
  background,
}) => {
  return (
    <>
      <div className="cloud-hero" style={{ background: `${gradientColor}` }}>
        <div className="cloud-head-main container">
          <div
            className="left-cap animate__animated animate__slideInUp"
            style={{ background: background }}
          >
            <span>{capText}</span>
          </div>
          <div className="hero-title animate__animated animate__slideInUp">
            <span>{titleText}</span>
          </div>
          <div className="hero-detail animate__animated animate__slideInUp">
            <p>{detailsText}</p>
          </div>
          <div className="hero-btn animate__animated animate__slideInUp">
            <a href={BOOK_NOW_LINK} target="_blank">
              <button onClick={() => {}}>Book a Demo</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
