import React, { useEffect, useState, useRef } from "react";
import P1 from "../../assets/svg/Home/Product/P1.svg";
import P2 from "../../assets/svg/Home/Product/P2.svg";
import P3 from "../../assets/svg/Home/Product/P3.svg";
import P4 from "../../assets/svg/Home/Product/P4.svg";
import P5 from "../../assets/svg/Home/Product/P5.svg";
import P6 from "../../assets/svg/Home/Product/P6.svg";
import { useNavigate } from "react-router-dom";
import "./product.css";
const PRODUCT_URL = process.env.REACT_APP_PRDUCT_URL;

const data = {
  P1: {
    heading: "Simplify your business with ",
    main: "SAP Business One",
    desc: "SAP Analytics Cloud helps you make faster, smarter, and accurate real-time decisions with integrated analytics, planning, and more powerful predictive tools—all in one easy-to-use platform.",
    image: P1,
    color: "#226DB4",
    bgcolor: "#EFF8FF",
    navigate: "solution/sap-b1",
  },
  P2: {
    heading: "Transform your operations with  ",
    main: "SAP S/4 HANA",
    desc: "SAP S/4 HANA is a next-gen ERP solution that streamlines operations and boosts efficiency. It empowers real-time, smarter decision-making for businesses of all sizes.",
    image: P2,
    color: "#DD6D02",
    bgcolor: "#FFF9E7",
    navigate: "solution/sap-s4-hana",
  },
  P3: {
    heading: "Turn data into action with ",
    main: "SAP Analytics Cloud",
    desc: "SAP Analytics Cloud helps you make faster, smarter, and accurate real-time decisions with integrated analytics, planning, and more powerful predictive tools—all in one easy-to-use platform.",
    image: P3,
    color: "#318280",
    bgcolor: "#F4FAF9",
    navigate: "solution/sap-Analytics",
  },
  P4: {
    heading: "Boost your SAP B1 efficiency with ",
    main: "SAP B1UP",
    desc: "SAP B1UP provides intuitive tools that easily adapt to your unique business needs, significantly boosting overall productivity and enabling faster, informed decision-making.",
    image: P4,
    color: "#6941C6",
    bgcolor: "#F9F5FF",
    navigate: "solution/sap-b1-up",
  },
  P5: {
    heading: "Make Fast Decisions with ",
    main: "Power BI",
    desc: "Unlock the power of your data with Power BI, turning complex information into clear insights. Make faster, informed decisions and drive success with our tailored solutions!",
    image: P5,
    color: "#E78F00",
    bgcolor: "#FEFBE8",
    navigate: "solution/power-bi-dashboard",
  },
  P6: {
    heading: "Make SAP B1 Even Better Today with ",
    main: "Add-Ons",
    desc: "Make SAP B1 Even Better Today with Add-Ons! Enhance your SAP Business One with powerful tools that streamline operations and boost productivity for greater success.",
    image: P6,
    color: "#DD2590",
    bgcolor: "#FDF3FA",
    navigate: "solution/sap-add-on",
  },
};

export default function Product() {
  const [display, setDisplay] = useState(data["P1"]);
  const [activeTab, setActiveTab] = useState("P1");
  const navigate = useNavigate();
  const imgRef = useRef(null); // Ref for the image

  const handleProductClick = (data) => {
    const url = `${String(PRODUCT_URL)}${String(data)}`;
    navigate(`/${data}`);
  };

  const OnTabChange = (e) => {
    setDisplay(data[e.target.id]);
    setActiveTab(e.target.id);
  };

  // Apply fade-in effect to the image
  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.classList.remove("fade-in");
      setTimeout(() => {
        imgRef.current.classList.add("fade-in");
      }, 10); // Slight delay to ensure the class is reapplied
    }
  }, [display]);

  return (
    <section className="product">
      <div className="product-box">
        <div className="product-box-nav">
          <ul>
            {Object.keys(data).map((key) => (
              <li
                id={key}
                key={key}
                className={activeTab === key ? "active" : ""}
                onClick={(e) => OnTabChange(e)}
              >
                {data[key].main}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="product-box-detail"
          style={{ background: display.bgcolor }}
        >
          <div className="product-box-detail-content">
            <h1>
              {display.heading}
              <span style={{ color: display.color }}>{display.main}</span>
            </h1>
            <p>{display.desc}</p>
            <button
              onClick={() => handleProductClick(display.navigate)}
              className="learnMore-btn"
            >
              Learn More
            </button>
          </div>
          <div className="product-box-detail-img">
            <img
              ref={imgRef} // Attach ref to the image
              alt={display.main}
              src={display.image}
              loading="lazy"
              className="fade-in"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
