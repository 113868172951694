import React from "react";
import "./ProductFeatureCard.css";

export const ProductFeatureCard = (featureCardData) => {
  return (
    <>
      <main className="ProductFeatureCard">
        {featureCardData.featureCardData.map((item, index) => {
          return (
            <div className="card-main" key={index}>
              <div className="card-image-section">
                <img
                  src={item.image}
                  alt="card-title"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
              <div className="card-detail-section">
                <div className="card-title-section">
                  <span>{item.headerText}</span>
                </div>
                <div className="card-desc-section">
                  <span>{item.detailText}</span>
                </div>
              </div>
            </div>
          );
        })}
      </main>
    </>
  );
};
