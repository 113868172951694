import React from "react";
import "./strategies.css";

export const StrategiesComponent = ({ strategiesComponentData, titleText }) => {
  return (
    <>
      <div className="common-outer container">
        {
          titleText && <div className="main-outer-container text-center">
            <span className="main-title">{titleText}</span>
          </div>
        }
        <div className="strategies-com-box">
          {strategiesComponentData.map((item, index) => {
            return (
              <div key={index} className="strategies-component">
                <div className="first-section">
                  <span className="header-content">{item.headerText}</span>
                </div>
                <div className="second-section">
                  <span className="section-detail">{item.detailText}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
