import React from "react";
import "./product-description.css";
const ProductDescription = ({ image, ProductName, TextColor, Discription }) => {
  return (
    <section>
      <div className="container">
        <div className="s2-box main-image-container">
          <div className="s2-box-img">
            <img
              src={image}
              height={"100%"}
              width={"100%"}
              loading="lazy"
              alt="product_image"
            />
          </div>
          <div className="s2-box-text ">
            <h1 style={{ color: "#475467" }} className="text-start">
              “What is{" "}
              <span className="" style={{ color: `${TextColor}` }}>
                {ProductName}
              </span>
              ?”, you asked.
            </h1>
            <p
              className="gray-text-muted text-start"
              style={{ fontWeight: "400", fontSize: "18px" }}
            >
              {Discription}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDescription;
