// Layout.jsx or Layout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import FooterPage from "./Pages/Footer/Footer";

const Layout = () => {
  return (
    <div id="layout-container">
      <Navbar />
      <main>
        <Outlet />
      </main>
      <FooterPage />
    </div>
  );
};

export default Layout;
