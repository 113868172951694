import { useEffect } from "react";
import { useState } from "react";

import S1 from "../../assets/svg/SBI/7-Manufacturing Image.svg";
import S2 from "../../assets/svg/SBI/3-Health Care image.svg";
import S3 from "../../assets/svg/SBI/10-Academic Institutions.svg";
import S4 from "../../assets/svg/SBI/6-Legal Care Image.svg";
import S5 from "../../assets/svg/SBI/2-Financial Services image.svg";
import S6 from "../../assets/svg/SBI/8-Real Estate image.svg";
import S7 from "../../assets/svg/SBI/5-IT Solution Image.svg";
import S8 from "../../assets/svg/SBI/4-Insurance image.svg";
import S9 from "../../assets/svg/SBI/1-Energy image.svg";
import S10 from "../../assets/svg/SBI/9-Retail Image.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "./solution.css";

export default function Solution() {
  const data = [
    {
      name: "Manufacturing",
      image: S1,
    },
    {
      name: "Health care",
      image: S2,
    },
    {
      name: "Academic Institutions",
      image: S3,
    },
    {
      name: "Law & Legal",
      image: S4,
    },
    {
      name: "Financial Services",
      image: S5,
    },
    {
      name: "Real Estate",
      image: S6,
    },
    {
      name: "IT Solution",
      image: S7,
    },
    {
      name: "Insurance",
      image: S8,
    },
    {
      name: "Energy",
      image: S9,
    },
    {
      name: "Retail",
      image: S10,
    },
  ];

  const [width, setWidth] = useState(window.innerWidth);
  const [frame, setFrame] = useState(3);
  const [frameWidth, setFrameWidth] = useState(10);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    switch (true) {
      case width <= 375:
        // Actions for phones
        setFrame(1);
        setFrameWidth(width - 60);
        break;
      case width > 375 && width <= 576:
        // Actions for phones
        setFrame(1);
        setFrameWidth(width - 150);
        break;

      case width > 576 && width <= 768:
        // Actions for tablets
        setFrame(2);
        setFrameWidth(200);
        break;

      case width > 768 && width <= 992:
        // Actions for tablets
        setFrame(3);
        setFrameWidth(200);
        break;

      case width > 992 && width <= 1200:
        // Actions for small laptops
        setFrame(4);
        setFrameWidth(200);
        break;

      case width > 1200:
        // Actions for big laptops and larger screens
        setFrame(5);
        setFrameWidth(250);
        break;

      default:
        break;
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <section className="service">
      <div className="container">
        <h1
          className="service-heading semibold-text text-center"
          style={{ color: "#344054", fontSize: "48px", fontWeight: "700" }}
        >
          Built for all, designed for you!
        </h1>
        <div className="w-100 m-auto">
          <Swiper
            modules={[Autoplay, Pagination]}
            spaceBetween={10}
            slidesPerView={frame}
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
            }}
            loop={true}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
          >
            {data.map((item, i) => (
              <SwiperSlide key={`swiper${i}`}>
                <div key={`S${i}`} className="eachslide">
                  <img
                    src={item.image}
                    loading="lazy"
                    height={140}
                    alt={item.name}
                  />
                  <h5 className="semibold-text text-center mt-3">
                    {item.name}
                  </h5>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
