import React from "react";
import "./service-feature.css";
import "animate.css";

export const ServiceFeatureComponent = ({ featureComponentData }) => {
  return (
    <>
      <div className="service-feature-component container">
        {featureComponentData.map((item, index) => {
          return (
            <div
              key={index}
              className="feature-component animate__animated animate__flipInX"
            >
              <div className="first-section">
                <div className="header-line"></div>
                <div className="header-content">
                  <span>{item.headerText}</span>
                </div>
              </div>
              <div className="second-section">
                <div className="section-detail">
                  <span>{item.detailText}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
