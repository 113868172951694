import React from "react";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import F1 from "../../../assets/svg/BapB1Up/F1.svg";
import F2 from "../../../assets/svg/BapB1Up/F2.svg";
import { SapFeatureSection } from "../../../components/sap-pages-components/sap-feature-section/SapFeatureSection";
import { StrategiesComponent } from "../../../components/Services/strategies-component/Strategies";

export const SapB1Up = () => {
  const featureData = [
    {
      image: F1,
      cardTitle: "Flexible Configuration Options",
      text1:
        "The package offers intuitive configuration tools that allow users to modify existing processes and workflows easily, ensuring the ERP system aligns seamlessly with their specific operational requirements.",
      text2:
        "Enhances user experience by streamlining everyday tasks and ensuring that the system reflects the organization's specific needs, ultimately driving efficiency and productivity.",
      flexDirection: "row",
    },
    {
      image: F2,
      cardTitle: "Customisable Dashboards",
      text1:
        "Users create personalised views of relevant metrics and KPIs. This feature enhances decision-making by providing quick access to tailored data, ultimately driving productivity and aligning efforts with organisational goals.",
      text2:
        "Team members can align on key objectives, track progress, and identify areas for improvement together.",
      flexDirection: "row-reverse",
    },
  ];

  const strategiesComponentData = [
    {
      headerText: "Make Smarter Choices",
      detailText:
        "B1UP empowers SAP B1 users to access data in innovative ways, easing resource strain and enhancing decision-making.",
    },
    {
      headerText: "Streamline Your Business",
      detailText:
        "SAP B1 processes to match your work style, boosting productivity and enabling greater achievements.",
    },
    {
      headerText: "No Coding Needed",
      detailText:
        "SAP B1 users can now enjoy customizations that were once reserved for SAP development experts.",
    },
  ];

  return (
    <>
      <div className="Sapb1up">
        <CloudHeroSection
          background={"#FFF7EECC"}
          capText={"SAP Usability Package: Simplify Your Workflow"}
          gradientColor={"linear-gradient(#FFF3E6CC, #F1F5F900)0"}
          titleText="Make SAP Business One easier to use with a No-Code Solution! - B1 Usability Package"
          detailsText="The B1 Usability Package enhances user experience by providing customizable features and streamlined workflows tailored to individual preferences.  B1 Usability Package customises SAP Business One to align with your unique workflows and requirements. It streamlines processes, automates tasks and improves the overall user experience."
        />
        <SapFeatureSection featureData={featureData} />
        <StrategiesComponent
          strategiesComponentData={strategiesComponentData}
          titleText={"B1 Usability Package customizes SAP B1"}
        />
      </div>
    </>
  );
};
