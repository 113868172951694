import React from "react";
import "./footer.css";
import {
  Direction,
  Email,
  Facebook,
  Linkdin,
  QitLogo,
  Youtube,
} from "../../assets";
import { useNavigate } from "react-router-dom";

const FooterPage = () => {
  const navigate = useNavigate();
  const handleCityClick = (data) => {
    console.log("click");
    switch (data) {
      case "surat":
        navigate("/sap-partner-surat");
        break;
      case "mumbai":
        navigate("/sap-partner-mumbai");
        break;
      case "bangalore":
        navigate("/sap-partner-bangalore");
        break;
      case "ahmedabad":
        navigate("/sap-partner-ahmedabad");
        break;
      case "vadodara":
        navigate("/sap-partner-vadodara");
        break;
      default:
        navigate("/");
    }
  };
  return (
    <>
      <div className="footer-main">
        <div className="container">
          <div className="footer-sub-main px-sm-0">
            <div className="footer-first-part mt-sm-3 mt-md-3">
              <p className="heading">Connect with us </p>
              <div className="footer-links">
                <a
                  href="https://www.facebook.com/profile.php?id=100063825674623"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/quantum-it-solution/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Linkdin} alt="Linkdin" />
                </a>
                <img src={Youtube} alt="Youtube" />
              </div>
              <div className="get-the-directions">
                <div className="direction-part">
                  <img src={Direction} width={15} height={19} alt="pin icon" />
                  <a
                    href="https://maps.app.goo.gl/J2TzWTirDY4h3ts7A"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>Get direction of office </p>
                  </a>
                </div>
                <div className="direction-part">
                  <img src={Email} width={19} height={15} alt="mail_icon" />
                  <a href="mailto:hr@qitsolution.co.in">
                    <p> hr@qitsolution.co.in </p>
                  </a>
                </div >
                <div className="direction-part">
                  <img src={Email} width={19} height={15} alt="email icon" />
                  <a href="mailto:keyur@qitsolution.co.in">
                    <p> keyur@qitsolution.co.in </p>
                  </a>
                </div >
              </div >
            </div >
            <div className="footer-second-part mt-sm-3 mt-md-3">
              <p className="heading"> Important Link </p>
              <div className="important-links">
                <p
                  onClick={() => {
                    handleCityClick("surat");
                  }}
                >
                  {" "}
                  SAP Business B1 Partner in Surat{" "}
                </p>
                <p
                  onClick={() => {
                    handleCityClick("mumbai");
                  }}
                >
                  {" "}
                  SAP Business One Partner in Mumbai{" "}
                </p>
                <p
                  onClick={() => {
                    handleCityClick("bangalore");
                  }}
                >
                  SAP Business B1 Partner in Bangalore{" "}
                </p>
                <p
                  onClick={() => {
                    handleCityClick("ahmedabad");
                  }}
                >
                  SAP Business One Partner in Ahmedabad{" "}
                </p>
                <p
                  onClick={() => {
                    handleCityClick("vadodara");
                  }}
                >
                  SAP Business One Partner in Vadodara{" "}
                </p>
              </div>
            </div>
            <div className="footer-third-part mt-sm-3 mt-md-3">
              <img src={QitLogo} alt="Company logo" />
              <div className="address">
                <p>
                  Shop No : 218, Sovereign Shoppers, Anand Mahal Rd, beside
                  Sindhu Seva Samiti School, Adajan, Surat, Gujarat 395009
                </p>
              </div>
            </div>
          </div >
        </div >
      </div >

      <div className="copy-right text-center">
        <i className="ri-copyright-line fs-5 align-middle"></i> {new Date().getFullYear()} Quantum IT Solution.
        All Rights Reserved.
      </div>
    </>
  );
};

export default FooterPage;
