import React, { useRef, useState } from "react";
import "./contact.css";
import { Contactus } from "../../../assets";
import emailjs from "@emailjs/browser";
import "animate.css";

export default function ContactPage() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = () => {
    emailjs
      .sendForm(
        "quantum_web",
        "template_f61zy4n",
        form.current,
        "bXsF7e66vVvo1KBtY"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          alert("Email sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Failed to send email. Please try again later.");
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <>
      <section className="contact-hero">
        <div className="contact-head-main container">
          <div className="hero-title animate__animated animate__slideInUp">
            <span>We would love to work with you on your Ideas.</span>
          </div>
          <div className="hero-detail animate__animated animate__slideInUp">
            <p>
              We believe in the power of collaboration and open communication.
              Whether you have questions, need assistance, or want to explore
              new opportunities, we're here for you. Connect with us today to
              start a conversation, share your insights, or discover how we can
              work together to achieve your goals. Your journey matters to us,
              and we look forward to connecting!
            </p>
          </div>
        </div>
      </section>
      <section className="contact-form-main">
        <div className="container contact-form-main-box">
          <div className="container-left">
            <img src={Contactus} alt="feature-pic" loading="lazy" />
          </div>
          <div className="container-right">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="What’s your full name/organization name?"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Type your email address"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="note">Note</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Note"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="form-group">
                <button type="submit" className="submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="find-us-main">
        <div className="container">
          <div className="hero-title">
            <span>Find Us here! 📍</span>
          </div>
          <div className="hero-detail">
            <p>
              Shop No : 218, Sovereign Shoppers, Anand Mahal Rd, beside Sindhu
              Seva Samiti School, nr. Gangeshwar Mahadev Temple, Honey Park, Adajan Gam, Adajan,
              Surat, Gujarat 395009
            </p>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.8098077120326!2d72.79125317622217!3d21.19971308049284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04dd469dd16d3%3A0xc343d60f44e7b590!2sQUANTUM%20IT%20SOLUTION!5e0!3m2!1sen!2sin!4v1729141411381!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="section-connect">
        <div className="container">
          <div className="connect-info">
            <div className="connect-info-text-section text-center">
              <div className="connect-info-text1">
                Wanna Connect? Please write to us.
              </div>
              <div className="connect-info-text2">hr@qitsolution.co.in</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
