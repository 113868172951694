import React, { useEffect, useState } from "react";
import S1 from "../../assets/svg/Home/Services/S1.png";
import S2 from "../../assets/svg/Home/Services/S2.png";
import S3 from "../../assets/svg/Home/Services/S3.png";
import S4 from "../../assets/svg/Home/Services/S4.png";
import S5 from "../../assets/svg/Home/Services/S5.png";
import "./solution.css"; // Import CSS file

export default function Solution() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    const solutionSection = document.querySelector(".solution");
    if (solutionSection) {
      observer.observe(solutionSection);
    }

    return () => {
      if (solutionSection) observer.unobserve(solutionSection);
    };
  }, []);

  const PreloadedImage = ({ src, alt }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const img = new Image();
      img.src = src;
      img.onload = () => setLoaded(true);

      return () => {
        img.onload = null;
      };
    }, [src]);

    return (
      <img
        className={`service-box-img ${loaded ? "loaded" : "loading"}`}
        src={src}
        alt={alt}
        loading="lazy"
      />
    );
  };

  const data = [
    {
      name: "SAP Partner",
      image: S1,
    },
    {
      name: "UX/UI Design",
      image: S2,
    },
    {
      name: "Website Dev",
      image: S3,
    },
    {
      name: "Mobile Dev",
      image: S4,
    },
    {
      name: "Cloud Infrastructure",
      image: S5,
    },
  ];

  return (
    <section className={`solution ${isVisible ? "animate" : ""}`}>
      <div className="container">
        <h1 className="text-dark text-center fw-bold">
          We Do It All: SAP to Stunning Solutions!
        </h1>
        <div className="service-box">
          {data.map((item, i) => (
            <div key={`S${i}`} className="box">
              <div className="eachslide">
                <PreloadedImage src={item.image} alt={item.name} />
              </div>
              <h5 className="semibold-text text-center mt-3">{item.name}</h5>
            </div>
          ))}
        </div>
        <div className="tools">
          <div className="container tools-box">
            <div className="scroll-container">
              <div className="scroll-track">
                {[...data, ...data].map((item, i) => (
                  <div key={`client-${i}`} className="client-box">
                    <img src={item.image} alt={item.name} />
                    <h5 className="semibold-text text-center mt-3">
                      {item.name}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
