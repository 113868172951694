import React from "react";
import "./current-opening.css";

export const CurrentOpenings = ({ titleText, currentComponentData }) => {
  return (
    <>
      <div className="main-tool-container animate__animated animate__slideInUp text-center">
        <span className="main-tool-title">{titleText}</span>
      </div>
      <div className="opening-component-box">
        {currentComponentData.map((item, index) => {
          return (
            <div key={index} className="opening-component">
              <div className="container d-flex justify-content-between align-items-center">
                <div className="first-section">
                  <span className="header-content">{item.openingName}</span>
                </div>
                <div className="second-section">
                  <span className="section-detail">{item.openingExp}</span>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfxv5jaWOnMMrIuCfxiepH3BnV4lZzbr4Kv1lEt7qIS6IlCBA/viewform?usp=sf_link"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="section-apply-now"
                  >
                    {item.applyNow}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
